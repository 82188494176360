<template>
  <div class="user-tab-overview">
    <v-card class="mb-6">
      <user-bio-panel
        class="d-flex justify-center"
        :user-data="userData"
      ></user-bio-panel>
      <v-card-title>
        Detail Pengguna
      </v-card-title>

      <v-card-text>
        <v-form class="multi-col-validation">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="user.name"
                label="Name"
                dense
                outlined
                disabled
              ></v-text-field>
            </v-col>
            <v-col
              v-if="role ==='school'"
              cols="12"
            >
              <v-text-field
                v-model="user.npsn"
                label="NPSN"
                type="number"
                dense
                outlined
                disabled
              ></v-text-field>
            </v-col>
            <v-col
              v-if="role ==='school'"
              cols="12"
              md="4"
            >
              <v-select
                v-model="user.semester"
                dense
                outlined
                item-text="name"
                item-value="name"
                label="Semester"
                :items="semesters"
              ></v-select>
            </v-col>
            <v-col
              v-if="role ==='school'"
              cols="12"
              md="4"
            >
              <v-select
                v-model="user.jenjang"
                dense
                outlined
                item-text="name"
                item-value="name"
                label="Jenjang"
                :items="levels"
              ></v-select>
            </v-col>
            <v-col
              v-if="role ==='school'"
              cols="12"
              md="4"
            >
              <v-select
                v-model="user.tahun_ajaran"
                dense
                outlined
                item-text="name"
                item-value="name"
                label="Tahun Ajaran"
                :items="school_years"
              ></v-select>
            </v-col>
            <v-col
              v-if="role !== 'admin'"
              cols="12"
            >
              <v-text-field
                v-model="user.username"
                label="Username"
                dense
                outlined
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="user.email"
                label="Email"
                dense
                type="email"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              v-if="role ==='school'"
              cols="12"
            >
              <v-text-field
                v-model="user.data_school"
                label="Link G-drive"
                dense
                type="text"
                outlined
                disabled
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <FileInput
                v-model="initialFile"
                :value="user.image"
                label="Upload Gambar"
                outlined
                dense
                :disabled="false"
                required
                :prependicon="icons.mdiImageMultipleOutline"
                @input="getFile"
              ></FileInput>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role === 'headmaster'"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="user.nik"
                label="NIK"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role === 'headmaster'"
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="user.nip"
                label="NIP"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role === 'headmaster'"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="user.school.name"
                label="Sekolah"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role === 'headmaster'"
              cols="12"
              md="6"
            >
              <v-select
                v-model="user.gender"
                dense
                outlined
                item-text="name"
                item-value="name"
                label="Jenis Kelamin"
                :items="genders"
              ></v-select>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role === 'headmaster'"
              cols="12"
              md="6"
            >
              <v-select
                v-model="user.religion"
                dense
                outlined
                item-text="name"
                item-value="name"
                label="Agama"
                :items="religions"
              ></v-select>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role === 'headmaster'"
              cols="12"
              md="6"
            >
              <v-select
                v-model="user.marital_status"
                dense
                outlined
                item-text="name"
                item-value="name"
                label="Status Menikah"
                :items="marital_status"
              ></v-select>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role === 'headmaster'"
              cols="12"
              md="6"
            >
              <v-menu
                v-model="date_of_work"
                :close-on-content-click="true"
                nudge-right="0"
                nudge-top="20"
                max-width="100%"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="user.date_of_work"
                    label="Tahun Kerja"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="user.date_of_work"
                  :first-day-of-week="0"
                  locale="id-id"
                  color="primary"
                  @input="date = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role === 'headmaster'"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="user.status_work"
                dense
                outlined
                label="Status Kerja"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role === 'headmaster'"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="user.first_degree"
                dense
                outlined
                label="Gelar Pertama"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role === 'headmaster'"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="user.last_degree"
                dense
                outlined
                label="Gelar Terakhir"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role === 'headmaster'"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="user.place_of_birth"
                dense
                outlined
                label="Tempat Lahir"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role === 'headmaster'"
              cols="12"
              md="6"
            >
              <v-menu
                v-model="date_of_birth"
                :close-on-content-click="true"
                nudge-right="0"
                nudge-top="20"
                max-width="100%"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="user.date_of_birth"
                    label="Tanggal Lahir"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="user.date_of_birth"
                  :first-day-of-week="0"
                  locale="id-id"
                  color="primary"
                  @input="date = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role ==='school' || role === 'headmaster'"
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="province_uuid"
                dense
                outlined
                item-text="name"
                item-value="uuid"
                return-object
                :items="provinces"
                label="Provinsi"
                disabled
                @change="changeListRegency()"
              ></v-autocomplete>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role ==='school' || role === 'headmaster'"
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="regency_uuid"
                item-text="name"
                item-value="uuid"
                return-object
                dense
                :items="regenciesByProvince"
                outlined
                label="Kabupaten"
                disabled
                @change="changeListDistrict()"
              ></v-autocomplete>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role ==='school' || role === 'headmaster'"
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="district_uuid"
                item-text="name"
                item-value="uuid"
                return-object
                dense
                :items="districtsByRegency"
                outlined
                label="Kecamatan"
                @change="changeListVillage()"
              ></v-autocomplete>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role ==='school' || role === 'headmaster'"
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="village_uuid"
                item-text="name"
                item-value="uuid"
                return-object
                dense
                :items="villagesByDistrict"
                outlined
                label="Kelurahan/Desa"
              ></v-autocomplete>
            </v-col>

            <v-col
              v-if="role ==='teacher' || role ==='school' || role === 'headmaster'"
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="user.rt"
                dense
                outlined
                label="RT"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role ==='school' || role === 'headmaster'"
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="user.rw"
                dense
                outlined
                label="Rw"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role ==='school' || role === 'headmaster'"
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="user.zip_code"
                dense
                outlined
                label="Kode Pos"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role === 'headmaster'"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="user.phone"
                dense
                outlined
                label="Nomor Telepon"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role === 'headmaster'"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="user.phone_house"
                dense
                outlined
                label="Telepon Rumah"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="role ==='teacher' || role === 'school' || role === 'headmaster'"
              cols="12"
            >
              <v-text-field
                v-model="user.address"
                dense
                outlined
                label="Alamat"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions class="mt-6 justify-center">
            <v-btn
              color="primary"
              large
              :loading="isLoadingButton"
              @click="handlerUpdate"
            >
              Update
            </v-btn>
            <v-btn
              color="primary"
              large
              outlined
              @click="returnBack"
            >
              Batal
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiImageMultipleOutline, mdiPaperclip } from '@mdi/js'
import FileInput from '../../../../components/FileInput.vue'
import UserBioPanel from '../user-bio-panel/UserBioPanel.vue'

export default {
  components: {
    FileInput,
    UserBioPanel,
  },

  data() {
    return {
      userOld: [],
      userNew: [],
      icons: {
        mdiImageMultipleOutline,
        mdiPaperclip,
      },
      users: [],
      user: {
        name: '',
        username: '',
        email: '',
        package: {},
        ability: {},
        place: {},
        school: {},
        major: {},
        nip: '',
        nik: '',
        first_degree: '',
        last_degree: '',
        date_of_birth: null,
        place_of_birth: '',
        gender: '',
        religion: '',
        marital_status: '',
        tahun_ajaran: '',
        jenjang: '',
        phone_house: '',
        phone: '',
        date_of_work: null,
        status_of_work: '',
        image: null,
        address: '',
        rt: null,
        rw: null,
        zip_code: null,
      },
      school_uuid: '',
      isLoadingButton: false,
      date_of_birth: false,
      date_of_work: false,
      imageSchool: {},
      teacher: {},
      student: {},
      roles: [],
      role: '',
      userData: {},
      userDetail: [],
      status: [
        { id: 1, name: 'Active' },
        { id: 2, name: 'Pending' },
      ],
      semesters: [
        { id: 1, name: 'Ganjil' },
        { id: 2, name: 'Genap' },
      ],
      levels: ['SD', 'SMP', 'SMA', 'SMK', 'MA/MTS/MI'],
      school_years: [
        '2021/2022',
        '2022/2023',
        '2023/2024',
        '2024/2025',
        '2025/2026',
        '2026/2027',
        '2027/2028',
        '2028/2029',
        '2029/2030',
      ],
      marital_status: [{ name: 'Sudah Menikah' }, { name: 'Belum Menikah' }],
      religions: [
        { name: 'Islam' },
        { name: 'Protestan' },
        { name: 'Katholik' },
        { name: 'Budha' },
        { name: 'Hindu' },
        { name: 'Konghuchu' },
      ],
      genders: ['Laki-Laki', 'Perempuan'],
      provinces: [],
      province_uuid: '',
      regenciesByProvince: [],
      regency_uuid: '',
      districtsByRegency: [],
      district_uuid: '',
      villagesByDistrict: [],
      village_uuid: '',
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.user.image === 'string') {
          return new File([this.user.image], this.user.image, { type: 'text/plain' })
        }

        return this.user.image
      },
      set() {
        return this.user.image
      },
    },
  },
  watch: {
    handler() {
      this.listProvince()
      this.changeListRegency()
      this.changeListDistrict()
      this.changeListVillage()
    },
    // image: {
    //   handler() {
    //     this.setBackUser()
    //   },
    // },
  },
  async mounted() {
    this.getRole()
    this.getImage()
    this.getUuid()
    await this.listProvince()
    await this.changeListRegency()
    await this.changeListDistrict()
    await this.changeListVillage()
  },
  methods: {
    getUser() {
      const { user } = JSON.parse(localStorage.getItem('user'))
      this.user.name = user.name
      this.user.email = user.email
    },
    async getSchool(uuid) {
      await this.$services.ApiServices.get('school', this.$route.params.uuid).then(({ data }) => {
        this.user = data.data
        this.province_uuid = this.user.place?.province?.uuid
        this.regency_uuid = this.user.place?.regencies?.uuid
        this.district_uuid = this.user.place?.district?.uuid
        this.village_uuid = this.user.place?.villages?.uuid
        this.user.jenjang = data.data.jenjang
        this.user.tahun_ajaran = data.data.tahun_ajaran
        this.user.rt = this.user.place.rt
        this.user.rw = this.user.place.rw
        this.user.zip_code = this.user.place.zip_code
        this.user.address = this.user.place.address
      })
    },
    async getEmployee(uuid) {
      await this.$services.ApiServices.get('employee', uuid).then(({ data }) => {
        this.user = data.data
        this.province_uuid = this.user.place.province.uuid
        this.regency_uuid = this.user.place.regencies.uuid
        this.district_uuid = this.user.place.district.uuid
        this.village_uuid = this.user.place.villages.uuid
        this.user.rt = this.user.place.rt
        this.user.rw = this.user.place.rw
        this.user.zip_code = this.user.place.zip_code
        this.user.address = this.user.place.address
      })
    },
    async listProvince() {
      await this.$services.ApiServices.list('province', { per_page: 'all' }).then(
        ({ data }) => {
          this.provinces = data.data
        },
        err => console.error(err),
      )
    },

    async changeListRegency() {
      await this.$services.ApiServices.list('regency', {
        per_page: 'all',
        province_uuid: typeof this.province_uuid === 'object' ? this.province_uuid.uuid : this.province_uuid,
      }).then(
        ({ data }) => {
          this.regenciesByProvince = data.data
        },
        err => console.error(err),
      )
    },
    async changeListDistrict() {
      await this.$services.ApiServices.list('district', {
        per_page: 'all',
        regencies_uuid: typeof this.regency_uuid === 'object' ? this.regency_uuid.uuid : this.regency_uuid,
      }).then(
        ({ data }) => {
          this.districtsByRegency = data.data
        },
        err => console.error(err),
      )
    },
    async changeListVillage() {
      if (this.district_uuid === null) {
        return
      }
      await this.$services.ApiServices.list('village', {
        per_page: 'all',
        district_uuid: typeof this.district_uuid === 'object' ? this.district_uuid.uuid : this.district_uuid,
      }).then(
        ({ data }) => {
          this.villagesByDistrict = data.data
        },
        err => console.error(err),
      )
    },
    getImage() {
      const { user } = JSON.parse(localStorage.getItem('user'))
      if (user.details[0].image === null || user.details[0].image === undefined) {
        return
      }
      this.user.image = user.details[0].image.split('/').pop()
    },
    getUuid() {
      this.uuid = JSON.parse(localStorage.getItem('user')).user.details
      this.uuid = this.uuid.forEach(item => {
        if (item.uuid === undefined) {
          this.uuid = null
        }
        this.user.uuid = item.uuid
        this.school_uuid = this.user.uuid
      })
    },
    async getRole() {
      this.user.role = JSON.parse(localStorage.getItem('user')).user.role
      if (this.user.role !== 'school' && this.user.role !== 'teacher' && this.user.role !== 'headmaster') {
        this.role = this.user.role
        await this.getUser()
      } else if (this.user.role === 'school') {
        this.role = this.user.role
        await this.getSchool(this.$route.params.uuid)
      } else if (this.user.role === 'teacher' || this.user.role === 'headmaster') {
        this.role = this.user.role
        await this.getEmployee(this.$route.params.uuid)
      }
    },
    handlerUpdate() {
      if (this.role === 'school') {
        this.updateSchool()
      } else if (this.role === 'teacher' || this.role === 'headmaster') {
        this.updateEmployee()
      }
    },
    async updateSchool() {
      this.isLoadingButton = true
      this.formData = new FormData()

      Object.keys(this.user).forEach((key, item) => {
        this.formData.append(key, this.user[key])
        if (
          // eslint-disable-next-line operator-linebreak
          this.user[key] === '' ||
          // eslint-disable-next-line operator-linebreak
          this.user[key] === null ||
          // eslint-disable-next-line operator-linebreak
          this.user[key] === [] ||
          // eslint-disable-next-line operator-linebreak
          typeof this.user[key] === 'undefined'
        ) {
          this.formData.delete(key)
        }
        this.formData.delete('place')
        this.formData.delete('package')
        this.formData.delete('ability')
        this.formData.delete('major')
      })
      if (typeof this.formData.get('image') === 'object') {
        this.formData.append('logo', this.formData.get('image'))
        this.formData.delete('image')
      } else {
        this.formData.delete('logo')
        this.formData.delete('image')
      }

      if (typeof this.province_uuid === 'object') {
        this.formData.append('province_uuid', this.province_uuid.uuid)
      } else {
        this.formData.append('province_uuid', this.province_uuid)
      }
      if (typeof this.regency_uuid === 'object') {
        this.formData.append('regencies_uuid', this.regency_uuid.uuid)
      } else {
        this.formData.append('regencies_uuid', this.regency_uuid)
      }
      if (typeof this.district_uuid === 'object' && this.district_uuid !== null) {
        this.formData.append('district_uuid', this.district_uuid.uuid)
      } else {
        this.formData.delete('district_uuid', this.district_uuid)
      }
      if (typeof this.village_uuid === 'object' && this.village_uuid !== null) {
        this.formData.append('villages_uuid', this.village_uuid.uuid)
      } else {
        this.formData.delete('villages_uuid', this.village_uuid)
      }

      await this.$services.ApiServices.update('school', this.formData, this.user.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.setBackUser(data.data)
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
    },
    async updateEmployee() {
      this.isLoadingButton = true
      this.formData = new FormData()

      Object.keys(this.user).forEach((key, item) => {
        this.formData.append(key, this.user[key])
        if (
          // eslint-disable-next-line operator-linebreak
          this.user[key] === '' ||
          // eslint-disable-next-line operator-linebreak
          this.user[key] === null ||
          // eslint-disable-next-line operator-linebreak
          this.user[key] === [] ||
          // eslint-disable-next-line operator-linebreak
          typeof this.user[key] === 'undefined'
        ) {
          this.formData.delete(key)
        }
        this.formData.delete('place')
        this.formData.delete('package')
        this.formData.delete('ability')
        this.formData.delete('major')
        this.formData.delete('teacher')
        this.formData.delete('school')
        this.formData.delete('is_active')
        this.formData.delete('is_moderator')
        this.formData.delete('certificate')
        this.formData.delete('total_certificate')
      })
      if (typeof this.formData.get('image') === 'object') {
        this.formData.append('photo', this.formData.get('image'))
        this.formData.delete('image')
      }
      if (typeof this.province_uuid === 'object') {
        this.formData.append('province_uuid', this.province_uuid.uuid)
      } else {
        this.formData.append('province_uuid', this.province_uuid)
        this.formData.delete('province_uuid')
      }
      if (typeof this.regency_uuid === 'object') {
        this.formData.append('regencies_uuid', this.regency_uuid.uuid)
      } else {
        this.formData.delete('regencies_uuid')
      }
      if (typeof this.district_uuid === 'object') {
        this.formData.append('district_uuid', this.district_uuid.uuid)
      } else {
        this.formData.delete('district_uuid')
      }
      if (typeof this.village_uuid === 'object') {
        this.formData.append('villages_uuid', this.village_uuid.uuid)
      } else {
        this.formData.delete('villages_uuid')
      }

      await this.$services.ApiServices.update('employee', this.formData, this.user.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.setBackUser(data.data)
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
    },
    setBackUser(data) {
      this.userOld = JSON.parse(localStorage.getItem('user'))
      this.userOld.user.name = data.name
      this.userOld.user.details.forEach(el => {
        el.image = data.image
      })
      localStorage.setItem('user', JSON.stringify(this.userOld))
      window.location.reload()
    },
    getFile(data) {
      this.user.image = data
    },
    returnBack() {
      this.$router.back()
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.project-progress {
  min-width: 4rem;
}
</style>
